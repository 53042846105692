<template>
  <div class="d-flex align-start">
    <app-country-selector
      return-object
      :default="defaultCountry"
      :value="country"
      @change="handleCountry"
      :disabled="disabled"
      :readonly="readonly"
      :items="codesCountries"
      :outlined="outlined"
    />
    <v-text-field
      ref="phoneInput"
      :value="number"
      :loading="loading"
      :label="label"
      :color="color"
      :error-messages="errorMessages"
      :disabled="!country || disabled"
      :readonly="readonly"
      @input="setPhone"
      :error="!isValid"
      :outlined="outlined"
      dense      
    >
      <template v-slot:append>
        <v-icon v-if="!isValid" color="error">mdi-block-helper</v-icon>
      </template>
    </v-text-field>
  </div>
</template>

<script>
  import CountrySelector from "./CountrySelector";
  import { countries } from "./CountrySelector/country";
  import { parsePhoneNumberFromString } from "libphonenumber-js";

  export default {
    name: "PhonePicker",
    components: {
      appCountrySelector: CountrySelector
    },
    data: () => ({
      number: null,
      country: null,
      dialCode: null
    }),
    props: {
      value: String,
      large: {
        type: Boolean,
        required: false,
        default: true
      },
      defaultCountry: {
        type: String,
        required: false,
        default: () => "TR"
      },
      errorMessages: {
        default: () => [],
        type: Array
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      readonly: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: "tertiary"
      },
      label: {
        type: String,
        required: false
      },
      outlined: Boolean
    },
    watch: {
      value(val) {
        this.parsePhone();
      }
    },
    computed: {
      codesCountries() {
        return countries;
      },
      isValid() {
        return !this.errorMessages.length;
      }
    },
    methods: {
      handleCountry(country) {
        if (country && country.dialCode && country.iso2) {
          this.dialCode = `+${country.dialCode}`;
          this.country = country.iso2;
        } else {
          //this.setPhone("");
          this.country = "";
          this.dialCode = "";
        }
      },
      setPhone(val) {
        let phoneNumber = parsePhoneNumberFromString(val, this.country);
        if (phoneNumber) {
          this.$emit("input", phoneNumber.number);
        } else {
          this.number = val;
          if (val) {
            this.$emit("input", `${this.dialCode}${val}`);
          } else {
            this.$emit("input", "");
          }
        }
      },
      parsePhone() {
        if (this.value) {
          let phoneNumber = parsePhoneNumberFromString(this.value);
          if (phoneNumber) {
            this.number = phoneNumber.nationalNumber;
            this.country = phoneNumber.country || this.country;
            this.dialCode = `+${phoneNumber.countryCallingCode}`;
          }
        } else {
          this.number = null;
        }
      }
    },
    created() {
      this.parsePhone();
    }
  };
</script>

<style scoped></style>
